<template>
  <div class="content">
    <div class="inner-content">
      <h2>SMTP settings</h2>
      <p>Configure SMTP settings</p>
      <form
        id="smtp"
      >
        <p>
          <label for="SMTPServer">SMTP Server</label>
          <input
            id="SMTPServer"
            v-model="SMTPServer"
            type="text"
            name="SMTPServer"
          >
        </p>

        <p>
          <label for="SMTPPort">SMTP Port</label>
          <input
            v-model="SMTPPort"
            type="number"
            name="SMTPPort"
          >
        </p>

        <p>
          <label for="SMTPUsername">SMTP Username</label>
          <input
            v-model="SMTPUsername"
            name="SMTPUsername"
          >
        </p>

        <p>
          <label for="SMTPPassword">SMTP Password</label>
          <input
            v-model="SMTPPassword"
            type="password"
            name="SMTPPassword"
          >
        </p>
        <p>
          <label for="SMTPFromAddress">SMTP 'From' Address</label>
          <input
            v-model="SMTPFromAddress"
            type="text"
            name="SMTPFromAddress"
          >
        </p>

        <p>
          <label for="useSSL">use SSL</label>
          <input
            v-model="useSSL"
            type="checkbox"
            name="useSSL"
          >
        </p>
      </form>
    </div>
  </div>
</template>

<script>
import { mapFields } from "@/helpers.js"

export default {
  name: 'SMTPForm',
  props: {},
  computed: {
    ...mapFields({
      fields: ["SMTPServer", "SMTPPort", 'SMTPUsername', 'SMTPPassword', 'SMTPFromAddress', 'useSSL'],
      base: "email",
      mutation: "SET_SMTP"
    })
  },
  methods: {}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
