import Vue from "vue";
import Vuex from "vuex";
 
Vue.use(Vuex);
 
export default new Vuex.Store({
 state: {
    email: {
      SMTPServer: {
        value: 'smtp.eu.mailgun.org',
        type: String
      },
      SMTPPort: {
        value: 587,
        type: Number
      },
      SMTPUsername: {
        value:  'invite@mg.energy21.com',
        type: String
      },
      SMTPPassword: {
        value: 'CaiSOhSgHPBryfjuURn0',
        type: String
      },
      useSSL: {
        value: true,
        type: Boolean
      },
      SMTPFromAddress: {
        value: '"Energy21" <no-reply@energy21.com>',
        type: String,
      },
      SMTPSubject: {
        value: 'Welcome to the E21 Portal',
        type: String
      }
    }
  },
  getters: {},
  mutations: {
    SET_SMTP (state, payload) {
      state.email[Object.keys(payload)].value = payload[Object.keys(payload)]
    },
  },
 actions: {}
});
