<template>
  <div id="app">
    <img
      class="logo"
      alt="E21 Logo"
      src="./assets/logo.png"
    >
    <div id="main">
      <invite msg="Welcome to the E21 Customer Invite Application" />
      <smtp />
    </div>
  </div>
</template>

<script>
import invite from './components/invite_form.vue'
import smtp from './components/smtp_form.vue'
import store from "./store";


export default {
  name: 'App',
  store,
  components: {
    invite,
    smtp,
  },
}

</script>

<style>
  @import './assets/styles.css';
</style>
